@charset "UTF-8";
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mm-menu, .mm-panels, .mm-panels > .mm-panel {
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  box-sizing: border-box;
}

.mm-btn, .mm-menu {
  box-sizing: border-box;
}

.mm-listview a, .mm-listview a:hover, .mm-navbar a, .mm-navbar a:hover {
  text-decoration: none;
}

.mm-listview .mm-next:before, .mm-listview > li:not(.mm-divider):after, .mm-next:after, .mm-prev:before {
  content: '';
  bottom: 0;
}

.mm-hidden {
  display: none !important;
}

.mm-menu, .mm-panels > .mm-panel:not(.mm-hidden) {
  display: block;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  padding: 0;
  position: absolute;
  bottom: 0;
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  position: absolute;
  bottom: 0;
}

.mm-btn, .mm-panel.mm-highest {
  z-index: 1;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-panel.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-listview .mm-divider, .mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-panels > .mm-panel:after, .mm-panels > .mm-panel:before {
  content: '';
  display: block;
  height: 20px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical > .mm-next:after, .mm-vertical .mm-listview > li > .mm-next:after {
  top: 16px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 19px;
}

.mm-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-next:after, .mm-prev:before {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
}

.mm-prev:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-next:after {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-navbar {
  border-bottom: 1px solid;
  border-color: inherit;
  text-align: center;
  line-height: 20px;
  height: 40px;
  padding: 0 40px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mm-navbar > * {
  display: block;
  padding: 10px 0;
}

.mm-navbar .mm-btn:first-child {
  left: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a, .mm-listview > li > span {
  color: inherit;
  display: block;
  padding: 10px 10px 10px 20px;
  margin: 0;
}

.mm-listview > li:not(.mm-divider):after {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  position: absolute;
  right: 0;
  left: 20px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next:before {
  border-left-width: 1px;
  border-left-style: solid;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mm-listview .mm-next + a, .mm-listview .mm-next + span {
  margin-right: 50px;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-panels > .mm-panel > .mm-listview {
  margin: 20px -20px;
}

.mm-panels > .mm-panel > .mm-listview:first-child, .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
  margin-top: -20px;
}

.mm-listview .mm-inset {
  list-style: disc inside;
  padding: 0 10px 15px 40px;
  margin: 0;
}

.mm-listview .mm-inset > li {
  padding: 5px 0;
}

.mm-listview .mm-divider {
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px;
}

.mm-listview .mm-spacer {
  padding-top: 40px;
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px;
}

.mm-menu {
  background: #f3f3f3;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-navbar a, .mm-menu .mm-navbar > * {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-btn:after, .mm-menu .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  tap-highlight-color: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.5);
}

.mm-menu .mm-divider, .mm-menu .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.05);
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  z-index: 1;
}

html.mm-opened {
  overflow-x: hidden;
  position: relative;
}

html.mm-blocking, html.mm-blocking body {
  overflow: hidden;
}

html.mm-background .mm-page {
  background: inherit;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

.mm-menu.mm-offcanvas.mm-current {
  display: block;
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-current.mm-opened {
  z-index: 10;
}

html.mm-opening .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

.mm-menu.mm-offcanvas.mm-autoheight.mm-bottom, .mm-menu.mm-offcanvas.mm-autoheight.mm-top {
  max-height: 80%;
}

.mm-menu.mm-offcanvas.mm-autoheight.mm-bottom.mm-fullscreen, .mm-menu.mm-offcanvas.mm-autoheight.mm-top.mm-fullscreen {
  max-height: 100%;
}

.mm-menu.mm-offcanvas.mm-measureheight .mm-panel {
  display: block !important;
}

.mm-menu.mm-offcanvas.mm-measureheight > .mm-panels > .mm-panel {
  bottom: auto !important;
  height: auto !important;
}

.mm-menu.mm-columns {
  -webkit-transition-property: width,max-width,-webkit-transform;
  transition-property: width,max-width,-webkit-transform;
  transition-property: transform,width,max-width;
  transition-property: transform,width,max-width,-webkit-transform;
}

.mm-menu.mm-columns .mm-panels > .mm-panel {
  width: 100%;
  right: auto;
  display: none;
  -webkit-transition-property: width,-webkit-transform;
  transition-property: width,-webkit-transform;
  transition-property: transform,width;
  transition-property: transform,width,-webkit-transform;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened {
  border-right: 1px solid;
  border-color: inherit;
  display: block;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-subopened {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  z-index: 2;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-0 {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-0 {
  display: block;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-0 {
  width: 80%;
  min-width: 140px;
  max-width: 0;
}

html.mm-opening.mm-columns-0 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-0 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 0px) {
  html.mm-right.mm-opening.mm-columns-0 .mm-slideout {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-1 {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-1 {
  display: block;
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-1 {
  width: 80%;
  min-width: 140px;
  max-width: 440px;
}

html.mm-opening.mm-columns-1 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(440px, 0);
    -ms-transform: translate(440px, 0);
    transform: translate(440px, 0);
    -webkit-transform: translate3d(440px, 0, 0);
    transform: translate3d(440px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-1 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening.mm-columns-1 .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

.mm-menu.mm-columns-1 .mm-panels > .mm-panel {
  width: 100%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-2 {
  -webkit-transform: translate(200%, 0);
  -ms-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-transform: translate3d(200%, 0, 0);
  transform: translate3d(200%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-2 {
  display: block;
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-2 {
  width: 80%;
  min-width: 140px;
  max-width: 880px;
}

html.mm-opening.mm-columns-2 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(880px, 0);
    -ms-transform: translate(880px, 0);
    transform: translate(880px, 0);
    -webkit-transform: translate3d(880px, 0, 0);
    transform: translate3d(880px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-2 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1100px) {
  html.mm-right.mm-opening.mm-columns-2 .mm-slideout {
    -webkit-transform: translate(-880px, 0);
    -ms-transform: translate(-880px, 0);
    transform: translate(-880px, 0);
    -webkit-transform: translate3d(-880px, 0, 0);
    transform: translate3d(-880px, 0, 0);
  }
}

.mm-menu.mm-columns-2 .mm-panels > .mm-panel {
  width: 50%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-3 {
  -webkit-transform: translate(300%, 0);
  -ms-transform: translate(300%, 0);
  transform: translate(300%, 0);
  -webkit-transform: translate3d(300%, 0, 0);
  transform: translate3d(300%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-3 {
  display: block;
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-3 {
  width: 80%;
  min-width: 140px;
  max-width: 1320px;
}

html.mm-opening.mm-columns-3 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(1320px, 0);
    -ms-transform: translate(1320px, 0);
    transform: translate(1320px, 0);
    -webkit-transform: translate3d(1320px, 0, 0);
    transform: translate3d(1320px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-3 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 1650px) {
  html.mm-right.mm-opening.mm-columns-3 .mm-slideout {
    -webkit-transform: translate(-1320px, 0);
    -ms-transform: translate(-1320px, 0);
    transform: translate(-1320px, 0);
    -webkit-transform: translate3d(-1320px, 0, 0);
    transform: translate3d(-1320px, 0, 0);
  }
}

.mm-menu.mm-columns-3 .mm-panels > .mm-panel {
  width: 33.34%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-4 {
  -webkit-transform: translate(400%, 0);
  -ms-transform: translate(400%, 0);
  transform: translate(400%, 0);
  -webkit-transform: translate3d(400%, 0, 0);
  transform: translate3d(400%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-4 {
  display: block;
  -webkit-transform: translate(500%, 0);
  -ms-transform: translate(500%, 0);
  transform: translate(500%, 0);
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-4 {
  width: 80%;
  min-width: 140px;
  max-width: 1760px;
}

html.mm-opening.mm-columns-4 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(1760px, 0);
    -ms-transform: translate(1760px, 0);
    transform: translate(1760px, 0);
    -webkit-transform: translate3d(1760px, 0, 0);
    transform: translate3d(1760px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-4 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 2200px) {
  html.mm-right.mm-opening.mm-columns-4 .mm-slideout {
    -webkit-transform: translate(-1760px, 0);
    -ms-transform: translate(-1760px, 0);
    transform: translate(-1760px, 0);
    -webkit-transform: translate3d(-1760px, 0, 0);
    transform: translate3d(-1760px, 0, 0);
  }
}

.mm-menu.mm-columns-4 .mm-panels > .mm-panel {
  width: 25%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-5 {
  -webkit-transform: translate(500%, 0);
  -ms-transform: translate(500%, 0);
  transform: translate(500%, 0);
  -webkit-transform: translate3d(500%, 0, 0);
  transform: translate3d(500%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-5 {
  display: block;
  -webkit-transform: translate(600%, 0);
  -ms-transform: translate(600%, 0);
  transform: translate(600%, 0);
  -webkit-transform: translate3d(600%, 0, 0);
  transform: translate3d(600%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-5 {
  width: 80%;
  min-width: 140px;
  max-width: 2200px;
}

html.mm-opening.mm-columns-5 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 2750px) {
  html.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(2200px, 0);
    -ms-transform: translate(2200px, 0);
    transform: translate(2200px, 0);
    -webkit-transform: translate3d(2200px, 0, 0);
    transform: translate3d(2200px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-5 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 2750px) {
  html.mm-right.mm-opening.mm-columns-5 .mm-slideout {
    -webkit-transform: translate(-2200px, 0);
    -ms-transform: translate(-2200px, 0);
    transform: translate(-2200px, 0);
    -webkit-transform: translate3d(-2200px, 0, 0);
    transform: translate3d(-2200px, 0, 0);
  }
}

.mm-menu.mm-columns-5 .mm-panels > .mm-panel {
  width: 20%;
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-columns-6 {
  -webkit-transform: translate(600%, 0);
  -ms-transform: translate(600%, 0);
  transform: translate(600%, 0);
  -webkit-transform: translate3d(600%, 0, 0);
  transform: translate3d(600%, 0, 0);
}

.mm-menu.mm-columns .mm-panels > .mm-panel.mm-opened:not([class*=mm-columns-]) ~ .mm-panel:not(.mm-opened).mm-columns-6 {
  display: block;
  -webkit-transform: translate(700%, 0);
  -ms-transform: translate(700%, 0);
  transform: translate(700%, 0);
  -webkit-transform: translate3d(700%, 0, 0);
  transform: translate3d(700%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-columns-6 {
  width: 80%;
  min-width: 140px;
  max-width: 2640px;
}

html.mm-opening.mm-columns-6 .mm-slideout {
  -webkit-transform: translate(80%, 0);
  -ms-transform: translate(80%, 0);
  transform: translate(80%, 0);
  -webkit-transform: translate3d(80%, 0, 0);
  transform: translate3d(80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 3300px) {
  html.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(2640px, 0);
    -ms-transform: translate(2640px, 0);
    transform: translate(2640px, 0);
    -webkit-transform: translate3d(2640px, 0, 0);
    transform: translate3d(2640px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-columns-6 .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 3300px) {
  html.mm-right.mm-opening.mm-columns-6 .mm-slideout {
    -webkit-transform: translate(-2640px, 0);
    -ms-transform: translate(-2640px, 0);
    transform: translate(-2640px, 0);
    -webkit-transform: translate3d(-2640px, 0, 0);
    transform: translate3d(-2640px, 0, 0);
  }
}

.mm-menu.mm-columns-6 .mm-panels > .mm-panel {
  width: 16.67%;
}

em.mm-counter {
  font: inherit;
  font-size: 14px;
  font-style: normal;
  text-indent: 0;
  line-height: 20px;
  display: block;
  margin-top: -10px;
  position: absolute;
  right: 45px;
  top: 50%;
}

em.mm-counter + a.mm-next {
  width: 90px;
}

em.mm-counter + a.mm-next + a, em.mm-counter + a.mm-next + span {
  margin-right: 90px;
}

em.mm-counter + a.mm-fullsubopen {
  padding-left: 0;
}

.mm-listview em.mm-counter + .mm-next.mm-fullsubopen + a, .mm-listview em.mm-counter + .mm-next.mm-fullsubopen + span, em.mm-counter + a.mm-fullsubopen + a, em.mm-counter + a.mm-fullsubopen + span {
  padding-right: 90px;
}

.mm-vertical > .mm-counter {
  top: 12px;
  margin-top: 0;
}

.mm-vertical.mm-spacer > .mm-counter {
  margin-top: 40px;
}

.mm-nosubresults > .mm-counter {
  display: none;
}

.mm-menu em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-divider > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  line-height: 25px;
}

.mm-divider.mm-opened a.mm-next:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-collapsed:not(.mm-uncollapsed) {
  display: none;
}

.mm-fixeddivider {
  background: inherit;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-fixeddivider:after {
  content: none !important;
  display: none !important;
}

.mm-hasdividers .mm-fixeddivider {
  display: block;
}

.mm-menu .mm-fixeddivider span {
  background: rgba(0, 0, 0, 0.05);
}

html.mm-opened.mm-dragging .mm-menu, html.mm-opened.mm-dragging .mm-slideout {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.mm-menu.mm-dropdown {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
}

html.mm-dropdown .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-dropdown #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-dropdown .mm-menu {
  z-index: 2;
}

html.mm-dropdown.mm-opened:not(.mm-opening) .mm-menu {
  display: none;
}

.mm-menu.mm-tip:before {
  content: '';
  background: inherit;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  z-index: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mm-menu.mm-tipleft:before {
  left: 22px;
}

.mm-menu.mm-tipright:before {
  right: 22px;
}

.mm-menu.mm-tiptop:before {
  top: -8px;
}

.mm-menu.mm-tipbottom:before {
  bottom: -8px;
}

.mm-iconpanel .mm-panel {
  -webkit-transition-property: left,-webkit-transform;
  transition-property: left,-webkit-transform;
  transition-property: transform,left;
  transition-property: transform,left,-webkit-transform;
}

.mm-iconpanel .mm-panel.mm-opened {
  border-left: 1px solid;
  border-color: inherit;
}

.mm-iconpanel .mm-panel.mm-subopened {
  overflow-y: hidden;
  left: -40px;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  left: 0;
}

.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  left: 40px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  left: 80px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  left: 120px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  left: 160px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  left: 200px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  left: 240px;
}

.mm-subblocker {
  background: inherit;
  opacity: 0;
  display: block;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

.mm-subopened .mm-subblocker {
  opacity: .6;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -100000px;
  left: 0;
  z-index: 3;
}

.mm-keyboardfocus a:focus {
  outline: 0;
}

.mm-menu.mm-keyboardfocus a:focus {
  background: rgba(255, 255, 255, 0.5);
}

.mm-navbar-bottom, .mm-navbar-top ~ .mm-navbar-top {
  border-bottom: none;
}

.mm-menu > .mm-navbar {
  background: inherit;
  padding: 0;
  z-index: 3;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-navbar-bottom {
  border-top-width: 1px;
  border-top-style: solid;
  top: auto;
  bottom: 0;
}

.mm-navbar-bottom ~ .mm-navbar-bottom {
  border-top: none;
}

.mm-navbar.mm-hasbtns {
  padding: 0 40px;
}

.mm-navbar[class*=mm-navbar-content-] > * {
  box-sizing: border-box;
  display: block;
  float: left;
}

.mm-navbar .mm-breadcrumbs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0 0 0 17px;
}

.mm-navbar .mm-breadcrumbs > * {
  display: inline-block;
  padding: 10px 3px;
}

.mm-navbar .mm-breadcrumbs > a {
  text-decoration: underline;
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
  margin-left: -40px;
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden) + .mm-breadcrumbs {
  margin-left: 0;
  padding-left: 0;
}

.mm-navbar-top-1 {
  top: 0;
}

.mm-hasnavbar-top-1 .mm-panels, .mm-navbar-top-2 {
  top: 40px;
}

.mm-hasnavbar-top-2 .mm-panels, .mm-navbar-top-3 {
  top: 80px;
}

.mm-hasnavbar-top-3 .mm-panels, .mm-navbar-top-4 {
  top: 120px;
}

.mm-hasnavbar-top-4 .mm-panels {
  top: 160px;
}

.mm-navbar-bottom-1 {
  bottom: 0;
}

.mm-hasnavbar-bottom-1 .mm-panels, .mm-navbar-bottom-2 {
  bottom: 40px;
}

.mm-hasnavbar-bottom-2 .mm-panels, .mm-navbar-bottom-3 {
  bottom: 80px;
}

.mm-hasnavbar-bottom-3 .mm-panels, .mm-navbar-bottom-4 {
  bottom: 120px;
}

.mm-hasnavbar-bottom-4 .mm-panels {
  bottom: 160px;
}

.mm-navbar-size-2 {
  height: 80px;
}

.mm-navbar-size-3 {
  height: 120px;
}

.mm-navbar-size-4 {
  height: 160px;
}

.mm-navbar-content-2 > * {
  width: 50%;
}

.mm-navbar-content-3 > * {
  width: 33.33%;
}

.mm-navbar-content-4 > * {
  width: 25%;
}

.mm-navbar-content-5 > * {
  width: 20%;
}

.mm-navbar-content-6 > * {
  width: 16.67%;
}

.mm-menu.mm-rtl {
  direction: rtl;
}

.mm-menu.mm-rtl.mm-offcanvas {
  right: auto;
}

.mm-menu.mm-rtl .mm-panel:not(.mm-opened) {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-rtl .mm-panel.mm-subopened {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:first-child {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-btn:last-child {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-navbar .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-navbar .mm-prev:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 23px;
  left: auto;
}

.mm-menu.mm-rtl .mm-listview > li:not(.mm-divider)::after {
  left: 0;
  right: 20px;
}

.mm-menu.mm-rtl .mm-listview > li > a:not(.mm-next), .mm-menu.mm-rtl .mm-listview > li > span:not(.mm-next) {
  padding-left: 10px;
  padding-right: 20px !important;
  margin-right: 0 !important;
}

.mm-menu.mm-rtl .mm-listview .mm-next {
  right: auto;
  left: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:before {
  left: auto;
  right: 0;
}

.mm-menu.mm-rtl .mm-listview .mm-next:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 23px;
  right: auto;
}

.mm-menu.mm-rtl .mm-listview .mm-next + a, .mm-menu.mm-rtl .mm-listview .mm-next + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview .mm-next.mm-fullsubopen + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter {
  left: 45px;
  right: auto;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + a, .mm-menu.mm-rtl .mm-listview em.mm-counter + .mm-fullsubopen + span {
  padding-left: 90px;
}

.mm-menu.mm-rtl label.mm-check, .mm-menu.mm-rtl label.mm-toggle {
  left: 20px;
  right: auto !important;
}

.mm-menu.mm-rtl label.mm-toggle + a, .mm-menu.mm-rtl label.mm-toggle + span {
  padding-left: 80px;
}

.mm-menu.mm-rtl label.mm-check + a, .mm-menu.mm-rtl label.mm-check + span {
  padding-left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check, .mm-menu.mm-rtl a.mm-next + label.mm-toggle {
  left: 60px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  margin-left: 50px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl a.mm-next + label.mm-toggle + span {
  padding-left: 70px;
}

.mm-menu.mm-rtl a.mm-next + label.mm-check + a, .mm-menu.mm-rtl a.mm-next + label.mm-check + span {
  padding-left: 50px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle {
  left: 100px;
}

.mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-check + span, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + a, .mm-menu.mm-rtl em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-left: 90px;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-] {
  left: 0;
}

.mm-menu.mm-rtl .mm-panel[class*=mm-iconpanel-].mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel {
  -webkit-transition-property: right,-webkit-transform;
  transition-property: right,-webkit-transform;
  transition-property: transform,right;
  transition-property: transform,right,-webkit-transform;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-0 {
  right: 0;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  right: 40px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  right: 80px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  right: 120px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-4 {
  right: 160px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-5 {
  right: 200px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-iconpanel-6 {
  right: 240px;
}

.mm-menu.mm-rtl.mm-iconpanel .mm-panel.mm-opened {
  border-left: none;
  border-right: 1px solid;
  border-color: inherit;
}

.mm-sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
}

.mm-search, .mm-search input {
  box-sizing: border-box;
}

.mm-search {
  height: 40px;
  padding: 7px 10px 0;
  position: relative;
}

.mm-search input {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
  border-radius: 4px;
  font: inherit;
  font-size: 14px;
  line-height: 26px;
  display: block;
  width: 100%;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  margin: 0;
  padding: 0 10px;
}

.mm-search input::-ms-clear {
  display: none;
}

.mm-search .mm-clear, .mm-search .mm-next {
  right: 0;
}

.mm-panel > .mm-search {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mm-panel.mm-hassearch {
  padding-top: 40px;
}

.mm-panel.mm-hassearch.mm-hasnavbar {
  padding-top: 80px;
}

.mm-panel.mm-hassearch.mm-hasnavbar .mm-search {
  top: 40px;
}

.mm-noresultsmsg {
  text-align: center;
  font-size: 21px;
  padding: 40px 0;
}

.mm-noresults .mm-indexer {
  display: none !important;
}

li.mm-nosubresults > a.mm-next {
  display: none;
}

li.mm-nosubresults > a.mm-next + a, li.mm-nosubresults > a.mm-next + span {
  padding-right: 10px;
}

.mm-panels > .mm-resultspanel:not(.mm-opened) {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.75);
}

.mm-menu .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-indexer {
  background: inherit;
  text-align: center;
  font-size: 12px;
  box-sizing: border-box;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100px;
  z-index: 3;
  -webkit-transition: right .4s ease;
  transition: right .4s ease;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-indexer a {
  text-decoration: none;
  display: block;
  height: 3.85%;
}

.mm-indexer ~ .mm-panel.mm-hasindexer {
  padding-right: 40px;
}

.mm-hasindexer .mm-indexer {
  right: 0;
}

.mm-hasindexer .mm-fixeddivider {
  right: 20px;
}

.mm-menu .mm-indexer a {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen), .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a:not(.mm-fullsubopen) {
  -webkit-transition: background .4s ease;
  transition: background .4s ease;
}

.mm-menu.mm-hoverselected .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.5);
}

input.mm-check, input.mm-toggle {
  position: absolute;
  left: -10000px;
}

label.mm-check, label.mm-toggle {
  margin: 0;
  position: absolute;
  top: 50%;
  z-index: 2;
  right: 20px;
}

label.mm-check:before, label.mm-toggle:before {
  content: '';
  display: block;
}

label.mm-toggle {
  border-radius: 30px;
  width: 50px;
  height: 30px;
  margin-top: -15px;
}

label.mm-toggle:before {
  border-radius: 30px;
  width: 28px;
  height: 28px;
  margin: 1px;
}

input.mm-toggle:checked ~ label.mm-toggle:before {
  float: right;
}

label.mm-check {
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

label.mm-check:before {
  border-left: 3px solid;
  border-bottom: 3px solid;
  width: 40%;
  height: 20%;
  margin: 25% 0 0 20%;
  opacity: .1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input.mm-check:checked ~ label.mm-check:before {
  opacity: 1;
}

li.mm-vertical label.mm-check, li.mm-vertical label.mm-toggle {
  bottom: auto;
  margin-top: 0;
  top: 5px;
}

label.mm-toggle + a, label.mm-toggle + span {
  padding-right: 80px;
}

label.mm-check + a, label.mm-check + span {
  padding-right: 60px;
}

a.mm-next + label.mm-check, a.mm-next + label.mm-toggle {
  right: 60px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span, a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  margin-right: 50px;
}

a.mm-next + label.mm-toggle + a, a.mm-next + label.mm-toggle + span {
  padding-right: 70px;
}

a.mm-next + label.mm-check + a, a.mm-next + label.mm-check + span {
  padding-right: 50px;
}

em.mm-counter + a.mm-next + label.mm-check, em.mm-counter + a.mm-next + label.mm-toggle {
  right: 100px;
}

em.mm-counter + a.mm-next + label.mm-check + a, em.mm-counter + a.mm-next + label.mm-check + span, em.mm-counter + a.mm-next + label.mm-toggle + a, em.mm-counter + a.mm-next + label.mm-toggle + span {
  margin-right: 90px;
}

.mm-menu label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu label.mm-toggle:before {
  background: #f3f3f3;
}

.mm-menu input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.75);
}

.mm-listview.mm-border-none > li:after, .mm-menu.mm-border-none .mm-listview > li:after {
  content: none;
}

.mm-listview.mm-border-full > li:after, .mm-menu.mm-border-full .mm-listview > li:after {
  left: 0 !important;
}

.mm-listview.mm-border-offset > li:after, .mm-menu.mm-border-offset .mm-listview > li:after {
  right: 20px;
}

.mm-menu.mm-effect-menu-zoom {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

html.mm-opening .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu.mm-effect-menu-slide {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
}

html.mm-opened .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(-30%, 0);
  -ms-transform: translate(-30%, 0);
  transform: translate(-30%, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

html.mm-opening .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(30%, 0);
  -ms-transform: translate(30%, 0);
  transform: translate(30%, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-effect-menu-fade {
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
}

html.mm-opening .mm-menu.mm-effect-menu-fade {
  opacity: 1;
}

.mm-menu.mm-effect-panels-zoom .mm-panel {
  -webkit-transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  transform: scale(1.5, 1.5) translate3d(100%, 0, 0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.mm-menu.mm-effect-panels-zoom .mm-panel.mm-opened {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

.mm-menu.mm-effect-panels-zoom .mm-panel.mm-opened.mm-subopened {
  -webkit-transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(-30%, 0, 0);
}

.mm-menu.mm-effect-panels-slide-0 .mm-panel.mm-subopened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-effect-panels-slide-100 .mm-panel.mm-subopened {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li {
  -webkit-transition: none .4s ease;
  transition: none .4s ease;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(1) {
  -webkit-transition-delay: 50ms;
  transition-delay: 50ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(2) {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(3) {
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(4) {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(5) {
  -webkit-transition-delay: 250ms;
  transition-delay: 250ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(6) {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(7) {
  -webkit-transition-delay: 350ms;
  transition-delay: 350ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(8) {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(9) {
  -webkit-transition-delay: 450ms;
  transition-delay: 450ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(10) {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(11) {
  -webkit-transition-delay: 550ms;
  transition-delay: 550ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(12) {
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(13) {
  -webkit-transition-delay: 650ms;
  transition-delay: 650ms;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(14) {
  -webkit-transition-delay: .7s;
  transition-delay: .7s;
}

.mm-menu[class*=mm-effect-listitems-] .mm-listview > li:nth-child(15) {
  -webkit-transition-delay: 750ms;
  transition-delay: 750ms;
}

.mm-menu.mm-effect-listitems-slide .mm-listview > li {
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  transform: translate(50%, 0);
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0);
  opacity: 0;
}

html.mm-opening .mm-menu.mm-effect-listitems-slide .mm-panel.mm-opened .mm-listview > li {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.mm-menu.mm-effect-listitems-fade .mm-listview > li {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 0;
}

html.mm-opening .mm-menu.mm-effect-listitems-fade .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
}

.mm-menu.mm-effect-listitems-drop .mm-listview > li {
  -webkit-transition-property: opacity,top;
  transition-property: opacity,top;
  opacity: 0;
  top: -25%;
}

html.mm-opening .mm-menu.mm-effect-listitems-drop .mm-panel.mm-opened .mm-listview > li {
  opacity: 1;
  top: 0;
}

.mm-menu.mm-offcanvas.mm-fullscreen {
  width: 100%;
  min-width: 140px;
  max-width: 10000px;
}

html.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(140px, 0);
    -ms-transform: translate(140px, 0);
    transform: translate(140px, 0);
    -webkit-transform: translate3d(140px, 0, 0);
    transform: translate3d(140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(10000px, 0);
    -ms-transform: translate(10000px, 0);
    transform: translate(10000px, 0);
    -webkit-transform: translate3d(10000px, 0, 0);
    transform: translate3d(10000px, 0, 0);
  }
}

html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

@media all and (max-width: 140px) {
  html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 10000px) {
  html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
    -webkit-transform: translate(-10000px, 0);
    -ms-transform: translate(-10000px, 0);
    transform: translate(-10000px, 0);
    -webkit-transform: translate3d(-10000px, 0, 0);
    transform: translate3d(-10000px, 0, 0);
  }
}

.mm-menu.mm-offcanvas.mm-fullscreen.mm-bottom, .mm-menu.mm-offcanvas.mm-fullscreen.mm-top {
  height: 100%;
  min-height: 140px;
  max-height: 10000px;
}

html.mm-opened.mm-fullscreen .mm-page {
  box-shadow: none !important;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview:after, .mm-menu .mm-panels > .mm-panel.mm-justified-listview:before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel:after, .mm-menu.mm-justified-listview .mm-panels > .mm-panel:before {
  content: none;
  display: none;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider), .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > a:not(.mm-next), .mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > span, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > a:not(.mm-next), .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > span {
  box-sizing: border-box;
  height: 100%;
}

.mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > a:not(.mm-next):before, .mm-menu .mm-panels > .mm-panel.mm-justified-listview > .mm-listview > li:not(.mm-divider) > span:before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > a:not(.mm-next):before, .mm-menu.mm-justified-listview .mm-panels > .mm-panel > .mm-listview > li:not(.mm-divider) > span:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  height: 100%;
  width: 0;
}

.mm-listview.mm-multiline > li .mm-listview > li.mm-multiline > a, .mm-listview.mm-multiline > li .mm-listview > li.mm-multiline > span, .mm-menu.mm-multiline .mm-listview > li > a, .mm-menu.mm-multiline .mm-listview > li > span {
  text-overflow: clip;
  white-space: normal;
}

html.mm-pagedim #mm-blocker, html.mm-pagedim-black #mm-blocker, html.mm-pagedim-white #mm-blocker {
  opacity: 0;
}

html.mm-pagedim-black.mm-opening #mm-blocker, html.mm-pagedim-white.mm-opening #mm-blocker, html.mm-pagedim.mm-opening #mm-blocker {
  opacity: .3;
  -webkit-transition: opacity .4s ease .4s;
  transition: opacity .4s ease .4s;
}

html.mm-pagedim #mm-blocker {
  background: inherit;
}

html.mm-pagedim-white #mm-blocker {
  background: #fff;
}

html.mm-pagedim-black #mm-blocker {
  background: #000;
}

.mm-menu.mm-popup {
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
  opacity: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 80%;
  min-height: 140px;
  max-height: 880px;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

html.mm-popup.mm-opening .mm-menu.mm-popup {
  opacity: 1;
}

html.mm-popup .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-popup #mm-blocker {
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important;
  z-index: 1;
}

html.mm-popup .mm-menu {
  z-index: 2;
}

.mm-menu.mm-offcanvas.mm-right {
  left: auto;
}

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
  -webkit-transform: translate3d(-80%, 0, 0);
  transform: translate3d(-80%, 0, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
    -webkit-transform: translate3d(-140px, 0, 0);
    transform: translate3d(-140px, 0, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-440px, 0);
    -ms-transform: translate(-440px, 0);
    transform: translate(-440px, 0);
    -webkit-transform: translate3d(-440px, 0, 0);
    transform: translate3d(-440px, 0, 0);
  }
}

html.mm-front .mm-slideout {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  z-index: 0;
}

html.mm-front #mm-blocker {
  z-index: 1;
}

html.mm-front .mm-menu.mm-offcanvas {
  z-index: 2;
}

.mm-menu.mm-offcanvas.mm-front, .mm-menu.mm-offcanvas.mm-next {
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease,-webkit-transform .4s ease;
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-front.mm-right, .mm-menu.mm-offcanvas.mm-next.mm-right {
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-menu.mm-offcanvas.mm-top {
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.mm-menu.mm-offcanvas.mm-bottom {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  top: auto;
}

.mm-menu.mm-offcanvas.mm-bottom, .mm-menu.mm-offcanvas.mm-top {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 80%;
  min-height: 140px;
  max-height: 880px;
}

html.mm-opening .mm-menu.mm-offcanvas.mm-front, html.mm-opening .mm-menu.mm-offcanvas.mm-next {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-menu.mm-shadow-page:after {
  content: "";
  display: block;
  width: 20px;
  height: 120%;
  position: absolute;
  left: 100%;
  top: -10%;
  z-index: 99;
}

.mm-menu.mm-shadow-page.mm-right:after {
  left: auto;
  right: 100%;
}

.mm-menu.mm-shadow-page.mm-front:after, .mm-menu.mm-shadow-page.mm-next:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-page:after, .mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-dark {
  background: #333;
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-navbar a, .mm-menu.mm-theme-dark .mm-navbar > *, .mm-menu.mm-theme-dark em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-btn:after, .mm-menu.mm-theme-dark .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview {
  border-color: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-dark .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-dark .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark .mm-divider, .mm-menu.mm-theme-dark .mm-fixeddivider span, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-dark .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-dark.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.05);
}

.mm-menu.mm-theme-dark label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-shadow-page.mm-theme-dark:after, .mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.mm-menu.mm-theme-dark .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-dark .mm-indexer a, .mm-menu.mm-theme-dark .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-dark .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-dark .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-dark label.mm-toggle {
  background: rgba(0, 0, 0, 0.15);
}

.mm-menu.mm-theme-dark label.mm-toggle:before {
  background: #333;
}

.mm-menu.mm-theme-dark input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-white {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white .mm-navbar a, .mm-menu.mm-theme-white .mm-navbar > *, .mm-menu.mm-theme-white em.mm-counter {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-btn:after, .mm-menu.mm-theme-white .mm-btn:before {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview {
  border-color: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white .mm-listview > li .mm-next:after {
  border-color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-theme-white .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  tap-highlight-color: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-white .mm-listview > li.mm-selected > span {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white .mm-divider, .mm-menu.mm-theme-white .mm-fixeddivider span, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-white .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-white.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(0, 0, 0, 0.03);
}

.mm-menu.mm-theme-white label.mm-check:before {
  border-color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-shadow-page.mm-theme-white:after, .mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mm-menu.mm-theme-white .mm-search input {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.6);
}

.mm-menu.mm-theme-white .mm-indexer a, .mm-menu.mm-theme-white .mm-noresultsmsg {
  color: rgba(0, 0, 0, 0.3);
}

.mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-white .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-white .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(0, 0, 0, 0.05);
}

.mm-menu.mm-theme-white label.mm-toggle {
  background: rgba(0, 0, 0, 0.1);
}

.mm-menu.mm-theme-white label.mm-toggle:before {
  background: #fff;
}

.mm-menu.mm-theme-white input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu.mm-theme-black {
  background: #000;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-navbar a, .mm-menu.mm-theme-black .mm-navbar > *, .mm-menu.mm-theme-black em.mm-counter {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-btn:after, .mm-menu.mm-theme-black .mm-btn:before {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview {
  border-color: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black .mm-listview > li .mm-next:after {
  border-color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black .mm-listview > li a:not(.mm-next) {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
  tap-highlight-color: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu.mm-theme-black .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-theme-black .mm-divider, .mm-menu.mm-theme-black .mm-fixeddivider span, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > .mm-panel, .mm-menu.mm-theme-black .mm-listview > li.mm-opened.mm-vertical > a.mm-next, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > .mm-panel, .mm-menu.mm-theme-black.mm-vertical .mm-listview > li.mm-opened > a.mm-next {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-check:before {
  border-color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a.mm-fullsubopen:hover + span, .mm-menu.mm-hoverselected.mm-theme-black .mm-listview > li > a:not(.mm-fullsubopen):hover, .mm-menu.mm-keyboardfocus a:focus, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + a, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected.mm-fullsubopen + span, .mm-menu.mm-parentselected.mm-theme-black .mm-listview > li > a.mm-selected:not(.mm-fullsubopen) {
  background: rgba(255, 255, 255, 0.3);
}

.mm-menu.mm-shadow-page.mm-theme-black:after {
  content: none;
  display: none;
}

.mm-menu.mm-shadow-panels .mm-panel.mm-opened:nth-child(n+2) {
  box-shadow: false;
}

.mm-menu.mm-theme-black .mm-search input {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
}

.mm-menu.mm-theme-black .mm-indexer a, .mm-menu.mm-theme-black .mm-noresultsmsg {
  color: rgba(255, 255, 255, 0.4);
}

.mm-menu.mm-theme-black label.mm-toggle {
  background: rgba(255, 255, 255, 0.2);
}

.mm-menu.mm-theme-black label.mm-toggle:before {
  background: #000;
}

.mm-menu.mm-theme-black input.mm-toggle:checked ~ label.mm-toggle {
  background: #4bd963;
}

.mm-menu .mm-tileview.mm-listview:after, .mm-menu.mm-tileview .mm-listview:after {
  content: '';
  display: block;
  clear: both;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next:after, .mm-menu .mm-tileview.mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-listview > li > .mm-next:after, .mm-menu.mm-tileview .mm-listview > li > .mm-next:before, .mm-menu.mm-tileview .mm-panel:after {
  content: none;
  display: none;
}

.mm-menu .mm-tileview.mm-listview > li, .mm-menu.mm-tileview .mm-listview > li {
  width: 50%;
  height: 0;
  padding: 50% 0 0;
  float: left;
  position: relative;
}

.mm-menu .mm-tileview.mm-listview > li:after, .mm-menu.mm-tileview .mm-listview > li:after {
  left: 0;
  top: 0;
  border-right-width: 1px;
  border-right-style: solid;
  z-index: -1;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xs, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xs {
  width: 12.5%;
  padding-top: 12.5%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-s, .mm-menu.mm-tileview .mm-listview > li.mm-tile-s {
  width: 25%;
  padding-top: 25%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-l, .mm-menu.mm-tileview .mm-listview > li.mm-tile-l {
  width: 75%;
  padding-top: 75%;
}

.mm-menu .mm-tileview.mm-listview > li.mm-tile-xl, .mm-menu.mm-tileview .mm-listview > li.mm-tile-xl {
  width: 100%;
  padding-top: 100%;
}

.mm-menu .mm-tileview.mm-listview > li > a, .mm-menu .mm-tileview.mm-listview > li > span, .mm-menu.mm-tileview .mm-listview > li > a, .mm-menu.mm-tileview .mm-listview > li > span {
  line-height: 1px;
  text-align: center;
  padding: 50% 10px 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0;
}

.mm-menu .mm-tileview.mm-listview > li > .mm-next, .mm-menu.mm-tileview .mm-listview > li > .mm-next {
  width: auto;
}

.mm-menu.mm-tileview .mm-panel {
  padding-left: 0;
  padding-right: 0;
}

.mm-menu.mm-tileview .mm-listview {
  margin: 0;
}

/***********Common themes**********/
.cs-container {
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 100%;
}

.cs-slider {
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 100%;
}

.cs-container a {
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICR‌​AEAOw==");
}

.cs-container img {
  max-width: 100%;
  display: block;
}

/*********************************/
/***Navigation arrows****/
.cs-container .cs-nav-next, .cs-container .cs-nav-prev {
  width: 45px;
  height: 45px;
  position: absolute;
  border-radius: 6px;
  background-image: url(images.png);
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  top: 50%;
}

.cs-container .cs-nav-next:hover, .cs-container .cs-nav-prev:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.cs-container .cs-nav-next {
  right: 0;
  background-position: -65px 2px;
}

.cs-container .cs-nav-prev {
  left: 0;
  background-position: -25px 2px;
}

/************************/
/*****nav bullets*********************/
.cs-container .cs-nav-cont {
  position: absolute;
  left: 45px;
  bottom: -25px;
  z-index: 20;
}

.cs-container .cs-nav-cont span {
  background-image: url(images.png);
  width: 24px;
  height: 24px;
  float: left;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  cursor: pointer;
}

.cs-container .cs-nav-cont span:hover, .cs-nav-cont span.cs-current {
  background-position: 0px -24px;
  opacity: 1.0;
}

/*************************************/
/******Play button*****/
.cs-container .cs-nav-play {
  background-image: url("images.png");
  background-position: -150px -6px;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 45px;
  bottom: -25px;
  cursor: pointer;
}

.cs-container .cs-nav-play.cs-nav-stop {
  background-position: -117px -6px;
}

/**************************/
/*****Title style*****/
.cs-container .cs-title {
  background-color: black;
  bottom: 0;
  color: white;
  display: none;
  float: left;
  font-family: 'Volkhov',Georgia,sans-serif;
  font-size: 130%;
  font-style: italic;
  font-weight: 400;
  left: 0;
  line-height: 38px;
  margin-bottom: 0;
  opacity: 0.8;
  padding: 0 10px;
  position: absolute;
  right: 0;
}

/***********************************/
.cs-container .cube-face {
  margin: 0;
  display: block;
  position: absolute;
  -moz-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cs-container .cs-shadow {
  position: absolute;
  bottom: -70px;
  left: 50%;
  margin-left: -368px;
  background: transparent url(shadow.png) no-repeat top left;
  height: 168px;
  width: 736px;
  z-index: -1;
}

/* Fallback Style */
.cs-slider-fb-wrapper {
  overflow: hidden;
}

.cs-slider-fb img {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
}

.cs-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(loader.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  z-index: 1001;
}

/**
 * Swiper 3.4.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: December 13, 2016
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

/* fresco */
/* box-sizing */
.fr-window, .fr-window [class^="fr-"],
.fr-overlay, .fr-overlay [class^="fr-"],
.fr-spinner, .fr-spinner [class^="fr-"] {
  box-sizing: border-box;
}

.fr-window {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font: 13px/20px "Lucida Sans", "Lucida Sans Unicode", "Lucida Grande", Verdana, Arial, sans-serif;
  /* Chrome hack, this fixes a visual glitch when quickly toggling a video */
  transform: translateZ(0px);
}

/* z-index */
.fr-overlay {
  z-index: 99998;
}

.fr-window {
  z-index: 99999;
}

.fr-spinner {
  z-index: 100000;
}

/* overlay */
.fr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.fr-overlay-background {
  float: left;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .9;
  filter: alpha(opacity=90);
}

.fr-overlay-ui-fullclick .fr-overlay-background {
  background: #292929;
  opacity: 1;
  filter: alpha(opacity=100);
}

/* mobile touch has position:absolute to allow zooming */
.fr-window.fr-mobile-touch,
.fr-overlay.fr-mobile-touch {
  position: absolute;
  overflow: visible;
}

/* some properties on the window are used to toggle things
 * like margin and the fullclick mode,
 * we reset those properties after measuring them
 */
.fr-measured {
  margin: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
}

.fr-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fr-pages {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fr-page {
  position: absolute;
  width: 100%;
  height: 100%;
}

.fr-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  text-align: center;
}

.fr-hovering-clickable .fr-container {
  cursor: pointer;
}

/*
   padding:
   youtube & vimeo always have buttons on the outside
   side button = 72 = 54 + (2 * 9 margin)
   close button = 48
*/
/* padding ui:inside */
.fr-ui-inside .fr-container {
  padding: 20px 20px;
}

.fr-ui-inside.fr-no-sides .fr-container {
  padding: 20px;
}

/* padding ui:outside */
.fr-ui-outside .fr-container {
  padding: 20px 82px;
}

.fr-ui-outside.fr-no-sides .fr-container {
  padding: 20px 48px;
}

/* reduce padding on smaller screens */
@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 700px) {
  /* padding ui:inside */
  .fr-ui-inside .fr-container {
    padding: 12px 12px;
  }
  .fr-ui-inside.fr-no-sides .fr-container {
    padding: 12px;
  }
  /* padding ui:outside */
  .fr-ui-outside .fr-container {
    padding: 12px 72px;
  }
  .fr-ui-outside.fr-no-sides .fr-container {
    padding: 12px 48px;
  }
}

@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px) {
  /* padding ui:inside */
  .fr-ui-inside .fr-container {
    padding: 0px 0px;
  }
  .fr-ui-inside.fr-no-sides .fr-container {
    padding: 0px;
  }
  /* padding ui:outside */
  .fr-ui-outside .fr-container {
    padding: 0px 72px;
  }
  .fr-ui-outside.fr-no-sides .fr-container {
    padding: 0px 48px;
  }
}

/* padding ui:fullclick */
.fr-ui-fullclick .fr-container {
  padding: 0;
}

.fr-ui-fullclick.fr-no-sides .fr-container {
  padding: 0;
}

/* video fullclick */
.fr-ui-fullclick.fr-type-video .fr-container {
  padding: 0px 62px;
}

.fr-ui-fullclick.fr-no-sides.fr-type-video .fr-container {
  padding: 48px 0px;
}

/* overflow-y should always have 0 padding top and bottom */
.fr-overflow-y .fr-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.fr-content,
.fr-content-background {
  position: absolute;
  top: 50%;
  left: 50%;
  /* IE11 has a 1px blur bug on the edges of the caption (ui:inside)
     during animation with overflow:hidden */
  overflow: visible;
}

/* img/iframe/error */
.fr-content-element {
  float: left;
  width: 100%;
  height: 100%;
}

.fr-content-background {
  background: #101010;
}

.fr-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #efefef;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.fr-info-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  line-height: 1%;
  filter: none;
  opacity: 1;
}

.fr-ui-inside .fr-info-background {
  filter: alpha(opacity=80);
  opacity: .8;
  zoom: 1;
}

/* ui:outside has a slightly lighter info background to break up from black page backgrounds */
.fr-ui-outside .fr-info-background {
  background: #0d0d0d;
}

/* info at the bottom for ui:inside */
.fr-content .fr-info {
  top: auto;
  bottom: 0;
}

.fr-info-padder {
  display: block;
  overflow: hidden;
  padding: 12px;
  position: relative;
  width: auto;
}

.fr-caption {
  width: auto;
  display: inline;
  white-space: wrap;
}

.fr-position {
  color: #b3b3b3;
  float: right;
  line-height: 21px;
  opacity: 0.99;
  position: relative;
  text-align: right;
  margin-left: 15px;
  white-space: nowrap;
}

/* positions not within the info bar
   for ui:outside/inside */
.fr-position-outside,
.fr-position-inside {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12px;
  width: auto;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 11px;
  line-height: 20px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  display: none;
  overflow: hidden;
  white-space: nowrap;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.fr-position-inside {
  border: 0;
}

.fr-position-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  filter: alpha(opacity=80);
  opacity: .8;
  zoom: 1;
  /* oldIE */
}

.fr-position-text {
  color: #b3b3b3;
}

.fr-position-outside .fr-position-text,
.fr-position-inside .fr-position-text {
  float: left;
  position: relative;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  opacity: 1;
}

/* display it for the outside ui, hide the caption inside */
.fr-ui-outside .fr-position-outside {
  display: block;
}

.fr-ui-outside .fr-info .fr-position {
  display: none;
}

.fr-ui-inside.fr-no-caption .fr-position-inside {
  display: block;
}

/* links */
.fr-info a,
.fr-info a:hover {
  color: #ccc;
  border: 0;
  background: none;
  text-decoration: underline;
}

.fr-info a:hover {
  color: #eee;
}

/* no-caption */
.fr-ui-outside.fr-no-caption .fr-info {
  display: none;
}

.fr-ui-inside.fr-no-caption .fr-caption {
  display: none;
}

/* --- stroke */
.fr-stroke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.fr-stroke-vertical {
  width: 1px;
  height: 100%;
}

.fr-stroke-horizontal {
  padding: 0 1px;
}

.fr-stroke-right {
  left: auto;
  right: 0;
}

.fr-stroke-bottom {
  top: auto;
  bottom: 0;
}

.fr-stroke-color {
  float: left;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.08);
}

/* the stroke is brighter inside the info box */
.fr-info .fr-stroke-color {
  background: rgba(80, 80, 80, 0.3);
}

/* remove the stroke when outside ui has a caption */
.fr-ui-outside.fr-has-caption .fr-content .fr-stroke-bottom {
  display: none;
}

/* remove stroke and shadow when ui:fullclick */
.fr-ui-fullclick .fr-stroke {
  display: none;
}

.fr-ui-fullclick .fr-content-background {
  box-shadow: none;
}

/* the info box never bas a top stroke */
.fr-info .fr-stroke-top {
  display: none;
}

/* < > */
.fr-side {
  position: absolute;
  top: 50%;
  width: 54px;
  height: 72px;
  margin: 0 9px;
  margin-top: -36px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  zoom: 1;
}

.fr-side-previous {
  left: 0;
}

.fr-side-next {
  right: 0;
  left: auto;
}

.fr-side-disabled {
  cursor: default;
}

.fr-side-hidden {
  display: none !important;
}

.fr-side-button {
  float: left;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.fr-side-button-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #101010;
  background-color: #ccc;
}

.fr-side-button-icon {
  float: left;
  position: relative;
  height: 100%;
  width: 100%;
  zoom: 1;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

/* smaller side buttons */
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-side {
    width: 54px;
    height: 60px;
    margin: 0;
    margin-top: -30px;
  }
  .fr-side-button {
    width: 48px;
    height: 60px;
    margin: 0 3px;
  }
}

/* X */
.fr-close {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.fr-close-background,
.fr-close-icon {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 26px;
  width: 26px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.fr-close-background {
  background-color: #000;
}

/* Thumbnails */
.fr-thumbnails {
  position: absolute;
  overflow: hidden;
}

.fr-thumbnails-disabled .fr-thumbnails {
  display: none !important;
}

.fr-thumbnails-horizontal .fr-thumbnails {
  width: 100%;
  height: 12%;
  min-height: 74px;
  max-height: 160px;
  bottom: 0;
}

.fr-thumbnails-vertical .fr-thumbnails {
  height: 100%;
  width: 10%;
  min-width: 74px;
  max-width: 160px;
  left: 0;
}

.fr-thumbnails,
.fr-thumbnails * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fr-thumbnails-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
}

.fr-thumbnails-vertical .fr-thumbnails-wrapper {
  top: 50%;
  left: 0;
}

.fr-thumbnails-slider {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  zoom: 1;
}

.fr-thumbnails-slider-slide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.fr-thumbnails-thumbs {
  float: left;
  height: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
}

.fr-thumbnails-slide {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.fr-thumbnail-frame {
  position: absolute;
  zoom: 1;
  overflow: hidden;
}

.fr-thumbnail {
  position: absolute;
  width: 30px;
  height: 100%;
  left: 50%;
  top: 50%;
  zoom: 1;
  cursor: pointer;
  margin: 0 10px;
}

.fr-ltIE9 .fr-thumbnail * {
  overflow: hidden;
  /* IE6(7) */
  z-index: 1;
  zoom: 1;
}

.fr-thumbnail-wrapper {
  position: relative;
  background: #161616;
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  display: inline;
  /* IE6(7) */
  z-index: 0;
  /* IE8 */
}

.fr-thumbnail-overlay {
  cursor: pointer;
}

.fr-thumbnail-active .fr-thumbnail-overlay {
  cursor: default;
}

.fr-thumbnail-overlay,
.fr-thumbnail-overlay-background,
.fr-thumbnail-overlay-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  overflow: hidden;
  border-style: solid;
  border-color: transparent;
}

.fr-ltIE9 .fr-thumbnail-overlay-border {
  border-width: 0 !important;
}

.fr-thumbnail .fr-thumbnail-image {
  position: absolute;
  filter: alpha(opacity=85);
  opacity: .85;
  max-width: none;
}

.fr-thumbnail:hover .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image {
  filter: alpha(opacity=99);
  opacity: .99;
}

.fr-thumbnail-active .fr-thumbnail-image,
.fr-thumbnail-active:hover .fr-thumbnail-image {
  filter: alpha(opacity=35);
  opacity: .35;
}

.fr-thumbnail-active {
  cursor: default;
}

/* Thumbnails loading */
.fr-thumbnail-loading,
.fr-thumbnail-loading-background,
.fr-thumbnail-loading-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fr-thumbnail-loading-background {
  background-color: #161616;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: .8;
  position: relative;
  float: left;
}

/* this element is there as an alternative to putting the loading image on the background */
.fr-thumbnail-loading-icon {
  display: none;
}

/* error on thumbnail */
.fr-thumbnail-error .fr-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
}

/* Thumbnail < > */
.fr-thumbnails-side {
  float: left;
  height: 100%;
  width: 28px;
  margin: 0 5px;
  position: relative;
  overflow: hidden;
}

.fr-thumbnails-side-previous {
  margin-left: 12px;
}

.fr-thumbnails-side-next {
  margin-right: 12px;
}

.fr-thumbnails-vertical .fr-thumbnails-side {
  height: 28px;
  width: 100%;
  margin: 10px 0;
}

.fr-thumbnails-vertical .fr-thumbnails-side-previous {
  margin-top: 20px;
}

.fr-thumbnails-vertical .fr-thumbnails-side-next {
  margin-bottom: 20px;
}

.fr-thumbnails-side-button {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -14px;
  margin-left: -14px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.fr-thumbnails-side-button-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  filter: alpha(opacity=80);
  opacity: .8;
  -moz-transition: background-color .2s ease-in;
  -webkit-transition: background-color .2s ease-in;
  transition: background-color .2s ease-in;
  background-color: #333;
  cursor: pointer;
  border-radius: 4px;
}

.fr-thumbnails-side-button:hover .fr-thumbnails-side-button-background {
  background-color: #3b3b3b;
}

.fr-thumbnails-side-button-disabled * {
  cursor: default;
}

.fr-thumbnails-side-button-disabled:hover .fr-thumbnails-side-button-background {
  background-color: #333;
}

.fr-thumbnails-side-button-icon {
  height: 42px;
  width: 42px;
  position: absolute;
  /*top: -7px;
  left: -7px;*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* vertical thumbnails */
.fr-thumbnails-vertical .fr-thumbnails-side,
.fr-thumbnails-vertical .fr-thumbnails-thumbs,
.fr-thumbnails-vertical .fr-thumbnail-frame {
  clear: both;
}

/* ui:fullclick has a dark thumbnail background */
.fr-window-ui-fullclick .fr-thumbnails {
  background: #090909;
}

/* put a stroke on the connecting caption*/
.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-horizontal .fr-info .fr-stroke-bottom {
  display: block !important;
}

/*({ box-shadow: inset 0 -1px 0 rgba(255,255,255,.1); } */
.fr-window-ui-fullclick.fr-thumbnails-enabled.fr-thumbnails-vertical .fr-info .fr-stroke-left {
  display: block !important;
}

/* thumbnail spacing  */
.fr-thumbnails-horizontal .fr-thumbnails-thumbs {
  padding: 12px 5px;
}

.fr-thumbnails-vertical .fr-thumbnails-thumbs {
  padding: 5px 12px;
}

.fr-thumbnails-measured .fr-thumbnails-thumbs {
  padding: 0 !important;
}

/* horizontal */
@media all and (min-height: 700px) {
  .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
    padding: 16px 8px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side {
    margin: 0 8px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
    margin-left: 16px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-next {
    margin-right: 16px;
  }
}

@media all and (min-height: 980px) {
  .fr-thumbnails-horizontal .fr-thumbnails-thumbs {
    padding: 20px 10px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side {
    margin: 0 10px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-previous {
    margin-left: 20px;
  }
  .fr-thumbnails-horizontal .fr-thumbnails-side-next {
    margin-right: 20px;
  }
}

/* vertical */
@media all and (min-width: 1200px) {
  .fr-thumbnails-vertical .fr-thumbnails-thumbs {
    padding: 8px 16px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side {
    margin: 0 8px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-previous {
    margin-top: 16px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-next {
    margin-bottom: 16px;
  }
}

@media all and (min-width: 1800px) {
  .fr-thumbnails-vertical .fr-thumbnails-thumbs {
    padding: 10px 20px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side {
    margin: 10px 0;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-previous {
    margin-top: 20px;
  }
  .fr-thumbnails-vertical .fr-thumbnails-side-next {
    margin-bottom: 20px;
  }
}

/* hide thumbnails on smaller screens
   the js always hides them on touch based devices
*/
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 500px) {
  .fr-thumbnails-horizontal .fr-thumbnails {
    display: none !important;
  }
}

@media all and (max-width: 700px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-thumbnails-vertical .fr-thumbnails {
    display: none !important;
  }
}

/* force fullClick: true
   and force overflow: false */
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-page {
    min-width: 100%;
  }
  /* fullclick */
  .fr-page {
    min-height: 100%;
  }
  /* overflow: false */
}

/* switch between ui modes
   we force everything not in the current UI mode to hide
*/
.fr-window-ui-fullclick .fr-side-next-outside,
.fr-window-ui-fullclick .fr-side-previous-outside,
.fr-window-ui-fullclick .fr-close-outside,
.fr-window-ui-fullclick .fr-content .fr-side-next,
.fr-window-ui-fullclick .fr-content .fr-side-previous,
.fr-window-ui-fullclick .fr-content .fr-close,
.fr-window-ui-fullclick .fr-content .fr-info,
.fr-window-ui-outside .fr-side-next-fullclick,
.fr-window-ui-outside .fr-side-previous-fullclick,
.fr-window-ui-outside .fr-close-fullclick,
.fr-window-ui-outside .fr-content .fr-side-next,
.fr-window-ui-outside .fr-content .fr-side-previous,
.fr-window-ui-outside .fr-content .fr-close,
.fr-window-ui-outside .fr-content .fr-info,
.fr-window-ui-inside .fr-page > .fr-info,
.fr-window-ui-inside .fr-side-next-fullclick,
.fr-window-ui-inside .fr-side-previous-fullclick,
.fr-window-ui-inside .fr-close-fullclick,
.fr-window-ui-inside .fr-side-next-outside,
.fr-window-ui-inside .fr-side-previous-outside,
.fr-window-ui-inside .fr-close-outside {
  display: none !important;
}

/* some ui elements can be toggled on mousemove.
   filter:alpha(opacity) is bugged so we use display in oldIE
*/
.fr-toggle-ui {
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}

.fr-visible-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-visible-inside-ui .fr-ui-inside .fr-toggle-ui {
  opacity: 1;
}

.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}

.fr-ltIE9.fr-hidden-fullclick-ui .fr-box > .fr-toggle-ui,
.fr-ltIE9.fr-hidden-inside-ui .fr-ui-inside .fr-toggle-ui {
  display: none;
}

/* Spinner */
.fr-spinner {
  position: fixed;
  width: 52px;
  height: 52px;
  background: #101010;
  background: rgba(16, 16, 16, 0.85);
  border-radius: 5px;
}

.fr-spinner div {
  position: absolute;
  top: 0;
  left: 0;
  height: 64%;
  width: 64%;
  margin-left: 18%;
  margin-top: 18%;
  opacity: 1;
  -webkit-animation: fresco-12 1.2s infinite ease-in-out;
  animation: fresco-12 1.2s infinite ease-in-out;
}

.fr-spinner div:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 8px;
  margin-left: -1px;
  background: #fff;
  box-shadow: 0 0 1px transparent;
  /* fixes rendering in Firefox */
}

.fr-spinner div.fr-spin-1 {
  -ms-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.fr-spinner div.fr-spin-2 {
  -ms-transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.fr-spinner div.fr-spin-3 {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.fr-spinner div.fr-spin-4 {
  -ms-transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.fr-spinner div.fr-spin-5 {
  -ms-transform: rotate(150deg);
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.fr-spinner div.fr-spin-6 {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.fr-spinner div.fr-spin-6 {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.fr-spinner div.fr-spin-7 {
  -ms-transform: rotate(210deg);
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.fr-spinner div.fr-spin-8 {
  -ms-transform: rotate(240deg);
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.fr-spinner div.fr-spin-9 {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.fr-spinner div.fr-spin-10 {
  -ms-transform: rotate(300deg);
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.fr-spinner div.fr-spin-11 {
  -ms-transform: rotate(330deg);
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

.fr-spinner div.fr-spin-12 {
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@-webkit-keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fresco-12 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Thumbnail spinner */
.fr-thumbnail-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
}

.fr-thumbnail-spinner-spin {
  position: relative;
  float: left;
  margin: 8px 0 0 8px;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #fff;
  -webkit-animation: fr-thumbnail-spin 1.1s infinite linear;
  animation: fr-thumbnail-spin 1.1s infinite linear;
}

.fr-thumbnail-spinner-spin,
.fr-thumbnail-spinner-spin:after {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

@-webkit-keyframes fr-thumbnail-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fr-thumbnail-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Error */
.fr-error {
  float: left;
  position: relative;
  background-color: #ca3434;
  width: 160px;
  height: 160px;
}

.fr-error-icon {
  position: absolute;
  width: 42px;
  height: 42px;
  top: 50%;
  left: 50%;
  margin-left: -21px;
  margin-top: -21px;
}

/* skin: 'fresco' */
/* Sprite */
.fr-window-skin-fresco .fr-side-button-icon,
.fr-window-skin-fresco .fr-close-icon,
.fr-window-skin-fresco .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url("../pic/skins/fresco/sprite.svg");
}

/* fallback png sprite */
.fr-window-skin-fresco.fr-no-svg .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-close-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url("../pic/skins/fresco/sprite.png");
}

.fr-window-skin-fresco .fr-error-icon {
  background-position: -160px -126px;
}

.fr-window-skin-fresco .fr-content-background {
  background: #101010;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.fr-window-skin-fresco.fr-window-ui-fullclick .fr-content-background {
  box-shadow: none;
}

/* thumbnail shadow */
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

/* < > */
.fr-window-skin-fresco .fr-side-button-background {
  background-color: transparent;
}

.fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
  background-position: -13px -14px;
}

.fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
  background-position: -93px -14px;
}

.fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
  background-position: -13px -114px;
}

.fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
  background-position: -93px -114px;
}

/* transition (png) */
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
  background-position: -13px -114px;
}

.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
  background-position: -93px -114px;
}

/* disabled state (png) */
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
  background-position: -13px -214px;
}

.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
  background-position: -93px -214px;
}

/* transition (svg) */
.fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
  background-position: -13px -114px;
}

.fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
  background-position: -93px -114px;
}

.fr-window-skin-fresco.fr-svg .fr-side-button-icon {
  opacity: .5;
}

.fr-window-skin-fresco.fr-svg .fr-side:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-next .fr-side-button-icon {
  opacity: 1;
}

.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side .fr-side-button-icon {
  opacity: .8;
}

/* touch always active but at lower opacity */
/* disabled (svg) */
.fr-window-skin-fresco.fr-svg .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-previous .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-hovering-next .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-side-disabled:hover .fr-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-side-disabled .fr-side-button-icon {
  opacity: .2;
}

/* TODO: make sure this works */
/* hide for ui:inside/fullclick, only for the image type */
.fr-window-skin-fresco.fr-window-ui-inside .fr-type-image .fr-side-disabled .fr-side-button-icon,
.fr-window-skin-fresco.fr-window-ui-fullclick.fr-showing-type-image .fr-side-disabled .fr-side-button-icon {
  background-image: none;
}

/* < > responsive */
@media all and (max-width: 500px) and (orientation: portrait), all and (orientation: landscape) and (max-height: 414px) {
  .fr-window-skin-fresco .fr-side-previous .fr-side-button-icon {
    background-position: 0px -300px;
  }
  .fr-window-skin-fresco .fr-side-next .fr-side-button-icon {
    background-position: -48px -300px;
  }
  .fr-window-skin-fresco .fr-side-previous:hover .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco .fr-side-next:hover .fr-side-button-icon {
    background-position: -48px -360px;
  }
  /* transition (png) */
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-previous .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg.fr-mobile-touch .fr-side-next .fr-side-button-icon {
    background-position: -48px -360px;
  }
  /* transition (svg) */
  .fr-window-skin-fresco.fr-svg .fr-side-previous .fr-side-button-icon {
    background-position: 0px -360px;
  }
  .fr-window-skin-fresco.fr-svg .fr-side-next .fr-side-button-icon {
    background-position: -48px -360px;
  }
  /* disabled state (png) */
  .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-previous .fr-side-previous.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-side-previous.fr-side-disabled:hover .fr-side-button-icon {
    background-position: 0px -420px;
  }
  .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-hovering-next .fr-side-next.fr-side-disabled .fr-side-button-icon,
  .fr-window-skin-fresco.fr-no-svg .fr-side-next.fr-side-disabled:hover .fr-side-button-icon {
    background-position: -48px -420px;
  }
}

/* X */
/* colors */
.fr-window-skin-fresco.fr-window-ui-outside .fr-close-background {
  background-color: #363636;
}

.fr-window-skin-fresco.fr-window-ui-outside .fr-close:hover .fr-close-background {
  background-color: #434343;
}

.fr-window-skin-fresco.fr-window-ui-inside .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close-background {
  background-color: #131313;
  filter: alpha(opacity=80);
  opacity: .8;
}

.fr-window-skin-fresco.fr-window-ui-inside .fr-close:hover .fr-close-background,
.fr-window-skin-fresco.fr-window-ui-fullclick .fr-close:hover .fr-close-background {
  background-color: #191919;
}

/* - image */
.fr-window-skin-fresco .fr-close .fr-close-icon {
  background-position: -168px -8px;
}

.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
  background-position: -210px -8px;
}

/* - transition */
.fr-window-skin-fresco.fr-svg .fr-close .fr-close-icon {
  background-position: -210px -8px;
  opacity: .8;
}

.fr-window-skin-fresco .fr-close:hover .fr-close-icon {
  opacity: 1;
}

/* iOS 8.4.1 bug: when opacity changes it'll require 2 taps
   force a single opacity to fix this
*/
.fr-window-skin-fresco.fr-svg.fr-mobile-touch .fr-close .fr-close-icon,
.fr-window-skin-fresco.fr-mobile-touch .fr-close:hover .fr-close-icon {
  opacity: 1;
}

/* Thumbnails */
.fr-window-skin-fresco .fr-thumbnail-wrapper {
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-wrapper {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.fr-window-skin-fresco .fr-thumbnail-wrapper {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.3);
}

.fr-window-skin-fresco .fr-thumbnail-overlay-border {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.08);
  /* should remain rgba */
}

/* no inner border on active thumbnail */
.fr-window-skin-fresco .fr-thumbnail-active .fr-thumbnail-overlay-border,
.fr-window-skin-fresco .fr-thumbnail-active:hover .fr-thumbnail-overlay-border {
  border: 0px;
}

/* Thumbnails < > */
.fr-window-skin-fresco .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
  background-position: -167px -49px;
}

.fr-window-skin-fresco .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
  background-position: -209px -49px;
}

.fr-window-skin-fresco .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
  background-position: -167px -91px;
}

.fr-window-skin-fresco .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
  background-position: -209px -91px;
}

/* vertical ^ (up/down) adjustments */
.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon {
  background-position: -293px -49px;
}

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-icon {
  background-position: -335px -49px;
}

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon {
  background-position: -293px -91px;
}

.fr-window-skin-fresco.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-icon {
  background-position: -335px -91px;
}

/* Thumbnails < > transition */
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-icon {
  -moz-transition: opacity .2s ease-in;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  opacity: .8;
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
  background-position: -167px -49px;
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
  background-position: -209px -91px;
}

.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-icon {
  opacity: 1;
}

/* vertical ^ (up/down) adjustments */
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled {
  background-position: -293px -49px;
}

.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled {
  background-position: -335px -91px;
}

/* lower opacity on disabled states */
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled,
.fr-window-skin-fresco.fr-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled {
  opacity: .5;
}

/* lower opacity IE < 9 using images */
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -251px -49px;
}

.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -251px -91px;
}

.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-background,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-background {
  filter: alpha(opacity=50);
}

/* vertical ^ (up/down) adjustments */
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-previous:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -377px -49px;
}

.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco.fr-no-svg.fr-thumbnails-vertical .fr-thumbnails-side-next:hover .fr-thumbnails-side-button-disabled .fr-thumbnails-side-button-icon {
  background-position: -377px -91px;
}
